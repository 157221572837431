// - - - - - - - - - - - - - - - - - - -
// - - settings
// global maps and variables
$charcoal: #282828;
$medium-grey: #777;
$medium-gray: $medium-grey;
$light-grey: #cacaca;
$light-gray: $light-grey;
$white: #fff;
$off-white: #eee;
$scarlet: #d74343;
$navy: #3d4f71;
$bright-blue: #7FA8F4;

$base-font-size: 16;
$base-font-color: $charcoal;

$body-font: maven-pro, sans-serif;
$header-font: maven-pro, sans-serif;

// Grid Variables
$columns: 24;
$column-width: 100 / $columns;

$gutter-width: 1em;

$screen-xxl: 150em;
$screen-xl: 112.5em;
$screen-lg: 75em;
$screen-md: 56.25em;
$screen-sm: 37.5em;
$screen-xs: 20em;

$container-xxl: 120rem;
$container-xl: 100rem;
$container-lg: 70rem;
$container-md: 52rem;
$container-sm: 100%;

$small-container-xxl: 100rem;
$small-container-xl: 80rem;
$small-container-lg: 60rem;
$small-container-md: 45rem;
$small-container-sm: 100%;

// Importing fonts
// Maven Pro
@font-face {
  font-family: maven-pro;
  src: url("/user/themes/towers/fonts/maven-pro/MavenPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: maven-pro;
  src: url("/user/themes/towers/fonts/maven-pro/MavenPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: maven-pro;
  src: url("/user/themes/towers/fonts/maven-pro/MavenPro-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: maven-pro;
  src: url("/user/themes/towers/fonts/maven-pro/MavenPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
}

// Roboto Slab
@font-face {
  font-family: roboto-slab;
  src: url("/user/themes/towers/fonts/roboto-slab/RobotoSlab-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: roboto-slab;
  src: url("/user/themes/towers/fonts/roboto-slab/RobotoSlab-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: roboto-slab;
  src: url("/user/themes/towers/fonts/roboto-slab/RobotoSlab-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: roboto-slab;
  src: url("/user/themes/towers/fonts/roboto-slab/RobotoSlab-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
}
