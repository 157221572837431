#media-player-container {}

.media-player {
  @extend .small-container;
  margin: 0 0 2rem;
  padding: 0;
  position: relative;

  &__art {
    width: 100%;
    padding-top: 100%;
    background-color: $charcoal;
    position: relative;
    box-shadow: 1px 1px 5px transparentize($charcoal, 0.35);

    & img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;

      &#track-art {
        z-index: 11;
      }
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5rem;
    padding: 1rem;
    box-shadow: 1px 1px 5px transparentize($charcoal, 0.35);
    background-color: $off-white;
  }
  @media (min-width: $screen-sm) {
    margin: 0 0 2rem;
    padding: 4rem 0 0 5.25rem;
    position: relative;

    &__art {
      position: absolute;
      top: 0;
      left: 0;
      width: 10rem;
      height: 10rem;
      padding-top: 0;
    }

    &__controls {
      height: 7.5rem;
      padding: 2rem 2rem 2rem 7.5rem;
    }
  }
}

.track-list {
  @extend .small-container;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 0 0.75rem;
    padding: 0.875rem 1.25rem;
    box-shadow: 1px 1px 5px transparentize($charcoal, 0.35);
    background-color: $off-white;

    &__image {
      display: none;
    }

    &--playing {
      color: $white;
      background-color: $bright-blue;
    }
  }
}

.range-input-container {
  display: flex;
  align-items: center;
}

.range-input {
  appearance: none;
  outline: none;
  height: 0.25rem;
  width: 100%;
  padding: 0;
  background-color: $charcoal;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    border: none;
    border-radius: 50%;
    background-color: $charcoal;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 1.25rem;
    height: 1.25rem;
    border: none;
    border-radius: 50%;
    background-color: $charcoal;
    cursor: pointer;
  }
}

.play-button {
  appearance: none;
  background: none;
  border: none;
  width: 2.5rem;
  height: 2.5rem;

  & svg {
    fill: $charcoal;
  }

  &__pause {
    display: none;
  }

  &__play {
    display: block;
  }

  &--playing {
    & .play-button {
      &__pause {
        display: block;
      }

      &__play {
        display: none;
      }
    }
  }

  &:focus,
  &:hover {
    outline: none;

    & svg {
      fill: $bright-blue;
    }
  }
}

.download-button {
  appearance: none;
  background: none;
  border: none;
  width: 2.5rem;
  height: 2.5rem;

  & svg {
    fill: $charcoal;
  }

  &:focus,
  &:hover {
    outline: none;

    & svg {
      fill: $bright-blue;
    }
  }
}

#play-pause {
  flex: 1 0 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  @media (min-width: $screen-sm) {
    flex: 1 0 4rem;
    width: 4rem;
    height: 4rem;
  }
}

#track-progress-container {
  flex: 1 0 calc(100% - 8rem);
  margin: 0 1rem;

  @media (min-width: $screen-sm) {
    flex: 1 0 calc(100% - 12rem);
    margin: 0 2rem;
  }
}

#volume-bar-container {
  display: none;
  @media (min-width: $screen-md) {
    display: block;
    height: 100%;
    flex: 1 0 4rem;
    position: relative;

    & .range-input {
      position: absolute;
      top: 50%;
      left: 25%;
      transform: translate(-50%, -50%) rotate(-90deg);
    }

    & .volume-icon {
      position: absolute;
      top: 50%;
      right: 0;
      width: 1.5rem;
      height: 1.5rem;
      fill: $charcoal;
      transform: translateY(-50%);

      &:hover {
        fill: $bright-blue;
      }

      & #normal {
        display: block;
      }

      & #muted {
        display: none;
      }

      &--muted {
        & #normal {
          display: none;
        }

        & #muted {
          display: block;
        }
      }
    }
  }
}

#volume-icon {}
