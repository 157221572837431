@mixin body-font() {
  font-family: $body-font;
  font-weight: 400;
}

@mixin header-font() {
  font-family: $header-font;
  font-weight: 400;
  font-style: normal;
}
