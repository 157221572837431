#header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 5.5rem;
  padding: 0.75rem 0 0.25rem;
  background-color: $charcoal;
  box-shadow: 0 2px 9px transparentize($charcoal, 0.3);

  @media (min-width: $screen-md) {
    flex-wrap: nowrap;
    height: 3rem;
    padding: 0;
  }
}

#logo {
  width: 100%;
  & a {
    display: block;
    margin: 0 auto;
    max-width: 8.5rem;

    & svg {
      fill: $white;
    }
  }

  &:hover {
    & a {
      & svg {
        fill: $bright-blue;
      }
    }
  }
  @media (min-width: $screen-md) {
    width: 12.5rem;
    height: 100%;
    padding: 0.75rem 0;

    & a {
      display: flex;
      height: 100%;
      max-width: none;
      padding: 0 1rem;

      & svg {
        height: 100%;
      }
    }
  }
}

#page-navigation {
  width: 100%;

  @media (min-width: $screen-md) {
    width: calc(100% - 12.5rem);
    margin: 0;
    padding: 0.6875rem 0;
    overflow-x: auto;
    background-color: transparent;
    box-shadow: none;
  }
}

.subnav {
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 0 1rem;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-style: normal;
    padding: 0;
    font-size: 1rem;

    & a {
      padding: 0.25rem 1rem;
      color: $white;
    }

    &:hover {
      & a {
        color: $bright-blue;
      }
    }
  }
  @media (min-width: $screen-sm) {

    &__item {
      & a {
        padding: 0.25rem 2.5rem;
      }
    }
  }
  @media (min-width: $screen-md) {
    justify-content: flex-end;
    padding: 0;

    &__item {
      padding: 0 3rem 0 0;
      & a {
        padding: 0;
      }
    }
  }
  @media (min-width: $screen-lg) {
    &__item {
      padding: 0 4rem 0 0;
      font-size: 1.125rem;
    }
  }
  @media (min-width: $screen-xxl) {
    &__item {
      padding: 0 5rem 0 0;
    }
  }
}
