.footer {
  position: relative;
  background-color: #282828;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 5rem 0.5rem;

  &__section {
    position: relative;
    z-index: 2;
    padding: 0 2.5rem;
    width: 100%;

    &__title {
      color: $off-white;
      margin: 0 0 0.75em;
      text-align: center;
    }

    &__links {
      display: flex;
      margin: 0;
      list-style: none;
      justify-content: center;
      align-items: center;

      &__item {
        margin: 0 1.25rem;

        & svg {
          width: 3rem;
          height: 3rem;
          fill: $off-white;
          transition: fill 500ms ease;
        }

        &:hover {
          & svg {
            fill: $bright-blue;
          }
        }
      }
    }

    &--listen {
      & .footer__section__title {
        margin: 1.25rem 0 0.75rem;
      }
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: -9999px;
    right: -9999px;
    bottom: 0;
    z-index: 0;
    opacity: 0.1;

    & img {
      min-width: 100vw;
      max-height: 100%;
    }
  }

  @media (min-width: $screen-md) {
    flex-wrap: nowrap;
    padding: 8rem 0;

    &__section {
      width: auto;


      &__title {
        font-size: 2rem;
      }


      &__links {
        &__item {
          margin: 0 0.75rem;

          & svg {
            width: 4rem;
            height: 4rem;
          }
        }
      }

      &--listen {
        & .footer__section__title {
          margin: 1.25rem 0 0.75rem;
        }
      }
    }

    &__background {
      display: flex;
      align-items: center;

      & img {
        min-width: 0;
        max-height: none;
        max-width: 100vw;
      }
    }
  }
  @media (min-width: $screen-lg) {
    padding: 11rem 0;

    &__section {
      &__title {
        font-size: 2.5rem;
      }

      &__links {
        &__item {
          margin: 0 1rem;

          & svg {
            width: 5rem;
            height: 5rem;
          }
        }
      }
    }
  }
  @media (min-width: $screen-xxl) {
    padding: 20rem 0;

    &__section {
      &__title {
        font-size: 3.5rem;
      }

      &__links {
        &__item {
          margin: 0 1.5rem;

          & svg {
            width: 7rem;
            height: 7rem;
          }
        }
      }
    }
  }
}
