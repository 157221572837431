.shows-section {}

.shows {
  @extend .small-container;
  display: block;
  max-width: 50rem;
  @media (min-width: $screen-xl) {
    max-width: 65rem;
  }
}

.show {
  width: 100%;
  margin: 0 0 12rem;
  padding: 1.5rem 1.5rem 11.5rem;
  background-color: $off-white;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.65);
  position: relative;

  &__name {
    text-align: center;
    margin: 0 0 0.5rem;
    line-height: 1;
  }

  &__datetime {
    text-align: center;
    margin-bottom: 1rem;
    line-height: 1;

    &__date {
      margin: 0;
    }

    &__time {
      margin: 0;
    }
  }

  &__location {
    text-align: center;
    line-height: 1;

    &__name {
      margin: 0;
    }

    &__address {
      margin: 0;
    }
  }

  &__map {
    background-color: #fff;
    overflow: hidden;
    position: absolute;
    left: -0.5rem;
    bottom: -9rem;
    width: calc(100% + 1rem);
    height: 18rem;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.65);
  }

  @media (min-width: $screen-md) {
    padding: 1.25rem;
    margin: 10rem 0;

    &__name {
      margin: 0;
      line-height: 1.25;
    }

    &__datetime,
    &__location {
      line-height: 1.25;
    }

    &__map {
      position: absolute;
      left: initial;
      bottom: 1.25rem;
      width: 17.5rem;
      height: 17.5rem;
      box-shadow: 2px 2px 10px rgba(0,0,0,0.65);
    }

    &:first-child {
      margin-top: 5rem;
    }

    &:nth-child(odd) {
      padding-right: 15.25rem;

      & .show {
        &__datetime,
        &__name {
          text-align: left;
        }

        &__location {
          text-align: right;
        }

        &__map {
          right: -4rem;
        }
      }
    }

    &:nth-child(even) {
      padding-left: 15.25rem;

      & .show {
        &__datetime,
        &__name {
          text-align: right;
        }

        &__location {
          text-align: left;
        }

        &__map {
          left: -4rem;
        }
      }
    }
  }
  @media (min-width: $screen-lg) {
    position: relative;
    padding: 1.5rem;
    margin: 10rem 0;

    &__map {
      position: absolute;
      bottom: 1.5rem;
      width: 20rem;
      height: 17.5rem;
    }

    &:nth-child(odd) {
      padding-right: 16.5rem;

      & .show {
        &__map {
          right: -5rem;
        }
      }
    }

    &:nth-child(even) {
      padding-left: 16.5rem;

      & .show {
        &__map {
          left: -5rem;
        }
      }
    }
  }
  @media (min-width: $screen-xl) {
    position: relative;
    padding: 2rem;
    margin: 19rem 0;

    &__map {
      position: absolute;
      bottom: 2rem;
      width: 30rem;
      height: 25rem;
    }

    &:nth-child(odd) {
      padding-right: 25rem;

      & .show {
        &__map {
          right: -7.5rem;
        }
      }
    }

    &:nth-child(even) {
      padding-left: 25rem;

      & .show {
        &__map {
          left: -7.5rem;
        }
      }
    }
  }
}
