// - - - - - - - - - - - - - - - - - - -
// - - base
// project defaults for base elements - h1-h6, p, a, etc.
html, body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
html {
  height: 100%;
}
body {
  padding: 0 0;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @media (min-width: $screen-md) {
    padding: 0;
  }
}
*, *:before, *:after {
  box-sizing: inherit;
}
a{
  color: $scarlet;
  text-decoration: none;
  cursor: pointer;
  transition: color 500ms;

  &:hover {
    color: lighten($scarlet, 10%); }
}
ul{
  margin: 0;
  padding: 0;
  margin-left: 2rem;
}
img,
video,
iframe {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
blockquote {
  margin: 1rem;
  position: relative;

  &:before,
  &:after {
    position: absolute;
    display: block;
    padding: 0.5rem;
    font-size: 1.5rem;
  }

  &:before {
    content: open-quote;
    top: -1rem;
    left: -1rem;
  }
  &:after {
    content: close-quote;
    bottom: -1rem;
    right: 0;
  }
}

svg {
  max-width: 100%;
  max-height: 100%;
  transition: fill 500ms ease;
}

section {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  @media (min-width: $screen-sm) {
    padding: 2rem;
  }

  @media (min-width: $screen-md) {
    min-height: 100vh;
    padding: 3rem 0;
  }

  @media (min-width: $screen-lg) {
    padding: 5rem 0;
  }

  @media (min-width: $screen-xl) {
    padding: 7rem 0;
  }

  @media (min-width: $screen-xxl) {
    padding: 10rem 0;
  }
}
