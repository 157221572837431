.section {
  &__title {
    @extend .container;
    display: block;
    color: $off-white;
    font-size: 2.5rem;
    text-align: center;
  }

  @media (min-width: $screen-md) {
    &__title {
       font-size: 2.75rem;
       text-align: left;
    }
  }

  @media (min-width: $screen-lg) {
    &__title {
       font-size: 3rem;
       text-align: left;
    }
  }
}
