// - - - - - - - - - - - - - - - - - - -
// - - typography
// global typography styles

$base-line-height: 1.5;
$vertical-rhythm: $base-line-height * $base-font-size;

html {
  font-size: 16px;
}

body {
  font-size: 100%;
  line-height: $base-line-height;
  @include body-font();
}
p {
  font-size: 1em; // Base font size
  margin: 0 0 1.25em; // Base vertical spacing: (1.5 * 1.25) = 1.875
}
h1, h2, h3, h4, h5, h6 {
  @include header-font();
  margin: 0 0 1em;
}
h1{
  font-size: 2.5em;
}
h2{
  font-size: 2em;
}
h3{
  font-size: 1.5em;
}
h4{
  font-size: 1.25em;
}
h5{
  font-size: 1.125em;
}
h6{
  font-size: 1em;
}
.page-container {
  padding: ($vertical-rhythm * 2) 2rem; // 3.5 is double the base value
}

@media (min-width: $screen-md) {
  h1{
    font-size: 3em;
  }
  h2{
    font-size: 2.25em;
  }
  h3{
    font-size: 1.75em;
  }
  h4{
    font-size: 1.5em;
  }
  h5{
    font-size: 1.25em;
  }
  h6{
    font-size: 1em;
  }
}
