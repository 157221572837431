.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 1em;
  font-size: 0.75rem;
  font-style: normal;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 500ms ease, color 500ms ease;

  &--navy {
    color: $white;
    background-color: $navy;

    &:hover {
      color: $white;
      background-color: darken($navy, 10%);
    }
  }

  &--scarlet {
    color: $white;
    background-color: $scarlet;

    &:hover {
      color: $white;
      background-color: darken($scarlet, 10%);
    }
  }

  &--charcoal {
    color: $white;
    background-color: $charcoal;

    &:hover {
      color: $white;
      background-color: lighten($charcoal, 10%);
    }
  }

  &:hover {
    color: $charcoal;
  }
  @media (min-width: $screen-md) {
    font-size: 0.875rem;
  }
  @media (min-width: $screen-xl) {
    font-size: 1rem;
  }
}

.hamburger {
  width: 3.75rem;
  height: 3.75rem;
  position: relative;
  padding: 0;
  border-radius: 0.25rem;
  background-color: $white;
  cursor: pointer;

  & > span {
    position: absolute;
    top: 1.8125rem;
    left: 1.125rem;
    width: 1.5rem;
    height: 0.125rem;
    background-color: $medium-grey;
    transition: all 500ms ease;

    &:first-child {
      top: 1.375rem;
    }

    &:last-child {
      top: 2.25rem;
    }
  }

  &--open {
    & > span {
      background-color: $navy;

      &:first-child {
        top: 1.8125rem;
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        left: 4rem;
      }

      &:last-child {
        top: 1.8125rem;
        transform: rotate(-45deg);
      }
    }
  }
}
