.members-section {}

.members {
  @extend .small-container;
  justify-content: center;
  padding: 0;
  @media (min-width: $screen-xl) {
    min-width: 85rem;
  }
}

.member {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 1.5rem;
  padding: 0;
  position: relative;
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 1px 1px 5px transparentize($charcoal, 0.15);

  &__details {
    flex: 1 0 100%;
    padding: 1rem 0.5rem;
    background-color: $white;

    &__instrument,
    &__name {
      width: 100%;
      margin: 0 0 0.25em;
      line-height: 1;
      text-align: center;
    }

    &__name {
      font-size: 1.75rem;
      line-height: 0.9;
    }

    &__instrument {
      font-size: 1.125rem;
    }

    &__facts {
      margin: 3rem 2rem 0;
      font-size: 1.125rem;
    }
  }

  &__image {
    flex: 1 0 100%;
    overflow: hidden;
  }

  &:nth-child(odd) {
    justify-content: flex-end;

    & .member {
      &__image {
        left: 1.5rem;
      }
    }
  }

  &:nth-child(even) {
    justify-content: flex-start;

    & .member {
      &__image {
        right: 1.5rem;
      }
    }
  }

  &:last-child {
    margin: 0;
  }
  @media (min-width: $screen-sm) {
    flex-wrap: nowrap;
    margin: 0 0 2.5rem;
    width: 100%;
    border-radius: 1rem;

    &__details {
      flex: 1 0 50%;
      padding: 1rem;
    }

    &__image {
      display: flex;
      justify-content: center;
      flex: 1 0 50%;

      & img {
        max-width: none;
        max-height: 26rem;
      }
    }

    &:nth-child(even) {
      & .member {
        &__image {
          order: 2;
        }
      }
    }
  }
  @media (min-width: $screen-md) {
    display: block;
    margin: 1rem auto;
    padding: 0;
    box-shadow: none;
    width: 19rem;

    &__details {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      flex: initial;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      padding: 0.5rem;
      border-radius: 50%;
      background-color: transparentize($white, 1);
      transition: background-color 500ms ease;

      &__instrument,
      &__name {
        margin: 0 0 0.25em;
        text-align: center;
        opacity: 0;
        transition: opacity 500ms ease;
      }

      &__name {
        font-size: 2.25rem;
        margin: 1rem 0 0;
      }

      &__instrument {
        font-size: 1.25rem;
      }
    }

    &__image {
      display: block;
      flex: initial;
      border-radius: 50%;

      & img {
        max-width: 100%;
        max-height: none;
      }
    }

    &:hover {
      & .member {
        &__details {
          background-color: transparentize($white, 0.2);

          &__instrument,
          &__name {
            opacity: 1;
          }
        }
      }
    }
  }
  @media (min-width: $screen-lg) {
    width: 22.5rem;
  }
  @media (min-width: $screen-xl) {
    width: 25rem;
    margin: 0 auto;

    &:last-child {
      margin: 0 auto;
    }
  }
}
