.promo {
  display: block;

  @media (min-width: $screen-sm) {
    display: flex;
  }
}

.promo__video-container {
  @extend .small-container;
  display: block;
  padding: 0;
}

.promo-video {
  box-shadow: 1px 1px 5px transparentize($charcoal, 0.35);
}
