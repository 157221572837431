// - - - - - - - - - - - - - - - - - - -
// - - skin
// global skin styles - gradients, colors, box-shadows, etc.
body {
  background-color: $white;
  color: $charcoal;
}
*, *:before, *:after {
  color: inherit;
}
h1, h2, h3, h4, h5, h6 {
  color: $charcoal;
}

input, select, textarea {
  background-color: $white;
  color: $charcoal;
  border: none;
  padding: 0.75em;
  transition: background-color 500ms, border-color 500ms, color 500ms;

  &:focus{
    outline: none;
    background-color: $white;
  }
  &:hover {
    outline: none;
  }
}

footer{
}
