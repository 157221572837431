.hero {
  position: relative;
  width: auto;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: auto;
  min-height: 0;
  overflow: hidden;

  &__video-container {
    display: none;
  }

  &__text {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    padding: 1rem;

    &__blurb,
    &__title {
      margin: 0;
      text-align: center;
      color: $white;
      text-shadow: 0 0 7px transparentize($charcoal, 0.25);
    }

    &__title {
      font-family: roboto-slab, serif;
      font-size: 2rem;
      text-transform: uppercase;
    }

    &__blurb {
      font-size: 1rem;
    }
  }
  @media (min-width: $screen-sm) {

    &__text {
      &__title {
        font-size: 2.5rem;
      }

      &__blurb {
        font-size: 1.25rem;
      }
    }
  }
  @media (min-width: $screen-md) {
    height: calc(100vh - 3rem);
    min-height: calc(100vh - 3rem);

    &__mobile-image {
      display: none;
    }
    &__video-container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__video {
      display: block;
      min-width: 100vw;
      min-height: 100vh;
      max-width: 110vw;
      object-fit: cover;
      opacity: 0;
      transition: opacity 500ms ease;

      &--active {
        opacity: 1;
      }
    }

    &__text {
      padding: 0;

      &__blurb,
      &__title {
        max-width: 45rem;
      }

      &__title {
        font-size: 3rem;
      }

      &__blurb {
        font-size: 1.5rem;
      }
    }
  }
  @media (min-width: $screen-lg) {
    &__text {
      &__blurb,
      &__title {
        max-width: 45rem;
      }

      &__title {
        font-size: 4rem;
      }

      &__blurb {
        font-size: 2rem;
      }
    }
  }
  @media (min-width: $screen-xl) {}
}
